import styled, { css } from 'styled-components';

const baseGridCss = css`
    --gap: 4rem;
    --row-gap: 6.4rem;
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(var(--gap) * -0.5);
    margin-right: calc(var(--gap) * -0.5);
    margin-top: calc(var(--row-gap) * -0.5);
    box-sizing: content-box;

    & > * {
        padding: 0 calc(var(--gap) * 0.5);
        margin-top: calc(var(--row-gap) * 0.5);
    }
`;

const responsiveGridCss = css<{ widths?: number[] }>`
    ${({ widths, theme }) =>
        widths &&
        `
            & > * {
                width: ${widths[0]}%;
            }

            @media screen and (min-width: ${theme.mediaQueries.s}) {
                & > * {
                    width: ${widths[Math.min(1, widths.length - 1)]}%;
                }
            }

            @media screen and (min-width: ${theme.mediaQueries.xl}) {
                & > * {
                    width: ${widths[Math.min(2, widths.length - 1)]}%;
                }
            }

            @media screen and (min-width: ${theme.mediaQueries.xxl}) {
                & > * {
                    width: ${widths[Math.min(3, widths.length - 1)]}%;
                }
            }
     `}
`;

const calcCarouselWidth = (percentage: number, length: number) => {
    const p = percentage / 100;

    return `
        width: calc(${percentage * length}% + var(--gap) * ${p * length});
    `;
};

const responsiveCarouselGridCss = css<{ length: number; widths?: number[] }>`
    ${({ widths, length, theme }) =>
        widths &&
        length &&
        `
            ${calcCarouselWidth(widths[0], length)}

            @media screen and (min-width: ${theme.mediaQueries.s}) {
                ${calcCarouselWidth(widths[Math.min(1, widths.length - 1)], length)}
            }

            @media screen and (min-width: ${theme.mediaQueries.xl}) {
               ${calcCarouselWidth(widths[Math.min(2, widths.length - 1)], length)}
            }

            @media screen and (min-width: ${theme.mediaQueries.xxl}) {
               ${calcCarouselWidth(widths[Math.min(3, widths.length - 1)], length)}
            }

            @media screen and (min-width: ${theme.mediaQueries.xxxl}) {
               ${calcCarouselWidth(widths[Math.min(4, widths.length - 1)], length)}
            }
     `}
`;

const WideGrid = styled.div<{ widths: number[] }>`
    ${baseGridCss}
    ${responsiveGridCss}
`;

export const CarouselGrid = styled.div<{ length: number; widths: number[] }>`
    ${baseGridCss}
    margin-left: calc(var(--gap) * -1);
    margin-right: 0;

    & > * {
        scroll-snap-align: center;
        width: ${({ length }) => 100 / length}%;
        :first-of-type {
            padding-left: var(--gap);
        }
        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
            padding-left: var(--gap);
        }
        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
            scroll-snap-align: end;
        }
        padding-right: 0;
    }

    ${responsiveCarouselGridCss}
`;

export const Cell = styled.div``;

export default WideGrid;
