import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FC } from 'react';

const ListPaginationLink: FC<React.PropsWithChildren<{ offset: number }>> = ({ children, offset }) => {
    const { query, pathname } = useRouter();

    return (
        <Link
            href={{
                pathname,
                query: {
                    ...query,
                    offset,
                },
            }}
            shallow
            passHref
            scroll
            legacyBehavior
        >
            {children}
        </Link>
    );
};

export default ListPaginationLink;
