import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import RoundButton from '@oberoninternal/travelbase-ds/components/action/RoundButton';
import Left from '@oberoninternal/travelbase-ds/components/figure/Left';
import Right from '@oberoninternal/travelbase-ds/components/figure/Right';
import Dots from '@oberoninternal/travelbase-ds/components/figure/Toggle';
import createRange from 'lodash.range';
import React, { ButtonHTMLAttributes, ComponentType, forwardRef, Fragment, Ref } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Cell, { CellProps } from './Cell';
import Stack from '../Stack';
import { Flex } from '@rebass/grid';

interface Props extends CellProps {
    pagesCount: number;
    currentPageIndex: number;
    LinkComponent: ComponentType<React.PropsWithChildren<{ offset: number }>>;
    amountPerPage?: number;
}

export const PAGE_MAX = 12;

// This function computes the range of the pagination buttons according to the position (offset)
const determineRange = (currentPageIndex: number, pagesCount: number): number[] => {
    const startIndex = 1;
    const endIndex = pagesCount - 1;

    if (pagesCount <= 4) {
        return createRange(startIndex, endIndex);
    }

    if (currentPageIndex < 3) {
        return createRange(startIndex, 4);
    }

    if (currentPageIndex >= pagesCount - 3) {
        return createRange(pagesCount - 4, endIndex);
    }

    return createRange(currentPageIndex - 1, currentPageIndex + 2);
};

const Pagination = ({ currentPageIndex, pagesCount, amountPerPage = PAGE_MAX, LinkComponent, ...rest }: Props) => {
    if (pagesCount <= 1) {
        // there's nothing to paginate, so show nothing
        return null;
    }

    const range = determineRange(currentPageIndex, pagesCount);

    return (
        <Cell gridColumn="1 / -1" style={{ display: 'flex', flex: 1, justifyContent: 'center' }} {...rest}>
            <StyledStack variant="inline" spacing={[3, 4]} mx="auto">
                <LinkComponent offset={amountPerPage * currentPageIndex - amountPerPage}>
                    <StepButton variant="previous" disabled={currentPageIndex === 0} />
                </LinkComponent>
                <Stack variant="inline" className="lt-s" spacing={[3, 4]}>
                    <Flex alignItems="center">{`${currentPageIndex + 1}/${pagesCount}`}</Flex>
                </Stack>

                <Stack variant="inline" className="gt-s" spacing={[3, 4]}>
                    <LinkComponent offset={0}>
                        <PageRoundButton variant={currentPageIndex === 0 ? 'primary' : 'outline'}>{1}</PageRoundButton>
                    </LinkComponent>
                    {range.map(i => {
                        const [start] = range;
                        const end = range[range.length - 1];

                        return (
                            <Fragment key={i}>
                                {start > 1 && i === start && (
                                    <DotsContainer>
                                        <Dots />
                                    </DotsContainer>
                                )}
                                <LinkComponent offset={amountPerPage * (i + 1) - amountPerPage}>
                                    <PageRoundButton variant={currentPageIndex === i ? 'primary' : 'outline'}>
                                        {i + 1}
                                    </PageRoundButton>
                                </LinkComponent>
                                {pagesCount - i >= 3 && i === end && (
                                    <DotsContainer>
                                        <Dots />
                                    </DotsContainer>
                                )}
                            </Fragment>
                        );
                    })}
                    <LinkComponent offset={amountPerPage * pagesCount - amountPerPage}>
                        <PageRoundButton variant={currentPageIndex === pagesCount - 1 ? 'primary' : 'outline'}>
                            {pagesCount}
                        </PageRoundButton>
                    </LinkComponent>
                </Stack>
                <LinkComponent offset={amountPerPage * (currentPageIndex + 1)}>
                    <StepButton variant="next" disabled={currentPageIndex === pagesCount - 1} />
                </LinkComponent>
            </StyledStack>
        </Cell>
    );
};

export default Pagination;

const DotsContainer = styled.div.attrs({ className: 'gt-xs' })`
    height: 4rem;
    display: flex;
    width: 1rem;
    > svg {
        margin: auto auto 0 auto;
        transform: rotate(90deg);
    }
`;

const StepButton = forwardRef(
    (
        {
            variant,
            ...props
        }: ButtonHTMLAttributes<HTMLButtonElement> & {
            variant: 'previous' | 'next';
        },
        ref: Ref<HTMLButtonElement>
    ) => {
        const icon = { previous: <Left />, next: <Right /> }[variant];
        return (
            <>
                <RoundButton
                    ref={ref}
                    style={{ textDecoration: 'none' }}
                    as="a"
                    variant="outline"
                    className="lt-m"
                    {...props}
                >
                    {icon}
                </RoundButton>
                <StyledButton ref={ref} direction={variant} rounded variant="outline" className="gt-m" {...props}>
                    <span>
                        {
                            {
                                previous: <FormattedMessage defaultMessage="vorige" />,
                                next: <FormattedMessage defaultMessage="volgende" />,
                            }[variant]
                        }
                    </span>
                    {icon}
                </StyledButton>
            </>
        );
    }
);

const StyledStack = styled(Stack)`
    justify-content: space-between;
    flex: 1;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        justify-content: inherit;
    }
`;

const StyledButton = styled(Button).attrs(({ disabled }) => ({ forwardedAs: disabled ? 'button' : 'a' }))<{
    direction: 'previous' | 'next';
}>`
    text-transform: capitalize;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    height: 4rem;

    > span {
        display: flex;
        flex-direction: ${({ direction }) => ({ previous: 'row-reverse', next: 'row' }[direction])};
        svg {
            ${({ direction }) => ({ previous: 'margin-right', next: 'margin-left' }[direction])}: 1.6rem;
        }
    }
`;

const PageRoundButton = styled(RoundButton).attrs({ forwardedAs: 'a' })`
    font: inherit;
    font-weight: 600;
    font-size: 15px;
    text-decoration: none;
    cursor: pointer;
`;
